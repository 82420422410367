import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext"; // Adjust import path as necessary

// Updated CSS styles as a JavaScript object
const styles = {
  container: {
    display: 'flex', // Use flexbox to layout the container
    justifyContent: 'space-between', // Distribute space between items and logout section
    alignItems: 'center', // Center items vertically
    backgroundColor: '#333', // Background color of the navbar
    color: 'white', // Text color
    padding: '10px 0', // Padding around the navbar (vertically)
  },
  linksContainer: {
    display: 'flex', // Use flexbox to layout links horizontally
    justifyContent: 'space-evenly', // Distribute space evenly around items
    alignItems: 'center', // Center items vertically
  },
  link: {
    color: 'white', // Link text color
    textDecoration: 'none', // Remove underline from links
    padding: '0 20px', // Padding around links (horizontally)
  },
  logoutButton: {
    marginRight: '20px', // Margin to the right of the logout button
    backgroundColor: 'transparent', // Transparent background
    border: 'none', // No border
    color: 'white', // Text color to match the nav links
    cursor: 'pointer', // Cursor pointer to indicate it's clickable
  }
};

function NavbarCMS() {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/'); // Redirect to login page after logout
    };

    return (
        <div style={styles.container}>
            <div style={styles.linksContainer}>
                {/* Links to different parts of your CMS */}
                <Link to="/cms" style={styles.link}>Začetna stran</Link>
                <Link to="/cms/blog-list" style={styles.link}>Blog List</Link>
                <Link to="/cms/blog" style={styles.link}>Dodaj Blog</Link>
                <Link to="/cms/raziskave-list" style={styles.link}>Raziskave List</Link>
                <Link to="/cms/raziskave" style={styles.link}>Dodaj Raziskavo</Link>
                <Link to="/cms/email" style={styles.link}>E-Novice</Link>
                {/* Add more navigation links as needed */}
            </div>
            <button onClick={handleLogout} style={styles.logoutButton}>Odjava</button>
        </div>
    );
}

export default NavbarCMS;
