import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  // Initially, check if the session is still valid
  const checkSessionValid = () => {
    const sessionExpiration = localStorage.getItem('sessionExpiration');
    return sessionExpiration && new Date().getTime() < sessionExpiration;
  };


  const [isAuthenticated, setIsAuthenticated] = useState(checkSessionValid());

  const login = (username, password) => {
    const storedUsername = process.env.REACT_APP_USERNAME;
    const storedPassword = process.env.REACT_APP_PASSWORD;
    if (username === storedUsername && password === storedPassword) {
    const sessionDuration = 120 * 60 * 1000; // 120 minutes in milliseconds
      const sessionExpiration = new Date().getTime() + sessionDuration;
      localStorage.setItem('sessionExpiration', sessionExpiration);
      setIsAuthenticated(true);
      return true;
    }
    return false;
  };

  const logout = () => {
    localStorage.removeItem('sessionExpiration');
    setIsAuthenticated(false);
  };

    // Use an effect to update isAuthenticated based on the current time and stored expiration
    useEffect(() => {
    const interval = setInterval(() => {
      setIsAuthenticated(checkSessionValid());
    }, 1000 * 60); // Check every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
