import React from "react";
import "./YouTubeSection.css";
import { Link } from "react-router-dom";

function YouTubeSection() {
  return (
    <div className="yt-section">
      <div className="triangle"></div>
      <div className="container section">
        <hr className="hr-styledb" />
        <h1 className="yt-title dancingfont">
          <strong>Moja dejavnost Hipnoterapije</strong>
        </h1>
        <div className="row">
          <div className="col-lg-7 yt-video">
            {/* YouTube video */}
            <video
              className="video"
              controls
              poster="https://hipnoterapija-md-bucket.s3.eu-central-1.amazonaws.com/logo.png"
            >
              <source
                src="https://hipnoterapija-md-bucket.s3.eu-central-1.amazonaws.com/Hipnoza+po+mo%C5%BEganski+kapi%2C+navdu%C5%A1ujo%C4%8De+kako+vam+lahko+pomaga+vzpostaviti+samozavest+in+in+motivacijo.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            {/* Description */}
            <p>
              "You're looking for that magical solution but you already have it.
              You're just not recognising it."
            </p>
            <Link to="/omeni" className="btn btn-light button mb-3">
              Več o meni
            </Link>
          </div>
          <div className="col-lg-5 opis">
            {/* Text on the right */}
            <p>
              Bistvo je človek in njegovi problemi s katerimi se sooča. Zdravi
              možgani, zdrav um in tako zdravo telo, pripomorejo k boljšemu
              življenju. Tukaj vam posredujem različne informacije in raziskave,
              ter svojo pomoč kot hipnoterapevtka.
              <br />
              Ali se soočaš s strahovi, zaskrbljenostjo, imaš zamere do ljudi?
              Najnovejša tematika, ki je še v povojih razvoja, pa je, kako si
              lahko s pomočjo hipnoterapije pomagajo preživeli po možganski kapi
              in njihovi svojci. Vse to in še več...
              <br />
              Namen spletne strani je, da se povezujemo in služimo en drugemu v
              pomoč. S tem se je začela kampanja dobre volje in motivacije za
              spremembe do pozitivne miselnosti in najti svoj notranji mir.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YouTubeSection;
