import React, { useEffect, useState } from 'react';



const EmailList = () => {

    const [emails, setEmails] = useState([]);


useEffect(() => {
  const fetchSubscribers = async () => {
    try {
      const response = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/emails', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_VALUE}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setEmails(data);
    } catch (error) {
      console.error('Error fetching subscribers:', error.message);
    }
  };

  fetchSubscribers();
}, []);


return (
  <>
    <div className="cms-blog-container">
      <h2 className="blog-list-title">Naročniki na E-Novice</h2>
      <ul className="blog-list">
        {emails.map((email) => (
          <li key={email.email.S} className="blog-list-item">
            <strong>{email.email.S}</strong> - {new Date(email.subscriptionDate.S).toLocaleDateString()}
          </li>
        ))}
      </ul>
    </div>
  </>
);
};

export default EmailList;